<template>
    <div class="card" style="min-height: calc(100vh - 180px)">
        <div class="bg-blue-light p-1">
            <TitleButton
                btnTitle="List"
                :showBtn="true"
                :showAddNew="false"
                title="Sales Order Add"
                @onClickCloseButton="navigateToListPage"
            />

            <div class="row mt-2 gy-1" v-if="visitClientId">
                <div class="col-md-3">
                    <label class="form-label">Order Date</label>
                    <input
                        class="form-control invoice-edit-input date-picker flatpickr-input flatpickr-mobile"
                        tabindex="1"
                        type="date"
                        placeholder=""
                        v-model="formData.date"
                    >
                </div>
                <div class="col-md-3">
                    <label class="form-label">Order Type</label>
                    <v-select
                        placeholder="Select Order Type"
                        v-model="formData.sale_type"
                        :options="orderTypes"
                        label="name"
                        :reduce="name => name.id"
                    />
                </div>
                <div class="col-md-3">
                    <div class="contact-detail">
                        <span>Client: </span>
                        <span> {{ singleContact.name }}</span>
                    </div>
                </div>
            </div>

            <div class="row gy-1" v-else>
                <div class="col-md-3">
                    <label class="form-label">Order Date</label>
                    <input
                        class="form-control invoice-edit-input date-picker flatpickr-input flatpickr-mobile"
                        tabindex="1"
                        type="date"
                        placeholder=""
                        v-model="formData.date"
                    >
                </div>
                <div class="col-md-3">
                    <label class="form-label">Order No</label>
                    <div class="d-flex align-items-center justify-content-md-end mb-1">
                        <div class="input-group input-group-merge invoice-edit-input-group">
                            <div class="input-group-text">
                                <span>{{ prefix }}-</span>
                            </div>
                            <input type="number" min="1" class="form-control invoice-edit-input" placeholder=""
                                   v-model="serial">
                        </div>
                    </div>
                </div>
                <div class="col-12 col-sm-6 col-md-4 col-lg-3 mt-tpp-1">
                    <label class="form-label">Select BDO</label>
                    <AsyncSelect
                        placeholder="Select BDO"
                        v-model="singleSR"
                        :api-service="fetchAllSR"
                        :additional-option="additionalSROption"
                    />
                </div>
                <div class="col-12 col-sm-6 col-md-4 col-lg-3 mt-tpp-1">
                    <label for="colFormLabel" class="form-label">Bank-Branch</label>
                    <AsyncSelect
                        placeholder="Select Bank-Branch"
                        v-model="singleDealer"
                        :api-service="fetchAllDealers"
                        :additional-query="additionalDealerQuery"
                        :additional-option="additionalDealerOption"
                        :key="dealerRenderKey"
                        :disabled="! Boolean(singleSR)"
                    />
                </div>
                <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                    <div class="d-flex align-items-center justify-content-between filter-label">
                        <label for="colFormLabel" class="form-label">Party: </label>
                        <span class="filter-search-icon cursor-pointer" @click="onOpenContactSearchModal">
                            <i class="fas fa-search"></i>
                        </span>
                    </div>
                    <AsyncSelect
                        placeholder="Select Party"
                        v-model="contactProfile"
                        :api-service="fetchContactProfiles"
                        :additional-query="additionalContactOption"
                        :format-label="formatPatientLabel"
                        :additional-option="additionalOption"
                        :option-selected="onChangeContact"
                        :key="contactRenderKey"
                    />
                </div>
                <div class="col-md-3">
                    <label class="form-label">Project</label>
                    <v-select
                        placeholder="Select Project"
                        v-model="formData.project_id"
                        :options="projects"
                        label="name"
                        :reduce="name => name.id"
                    />
                </div>
                <div class="col-md-3">
                    <label class="form-label">Business Unit</label>
                    <v-select
                        placeholder="Select Business"
                        v-model="formData.business_id"
                        :options="business"
                        label="name"
                        :reduce="name => name.id"
                    />
                </div>
                <div class="col-md-3">
                    <label class="form-label">Cost Centre</label>
                    <v-select
                        placeholder="Select Cost Centre"
                        v-model="formData.cost_centre_id"
                        :options="costCentres"
                        label="name"
                        :reduce="name => name.id"
                    />
                </div>
                <div class="col-md-3">
                    <label class="form-label">Order No/PI No</label>
                    <input
                        class="form-control invoice-edit-input date-picker flatpickr-input flatpickr-mobile"
                        tabindex="1"
                        type="text"
                        placeholder="Please add order number"
                        v-model="formData.order_number"
                    >
                </div>
                <div class="col-md-3">
                    <label class="form-label">Sales Person</label>
                    <AsyncSelect
                        placeholder="Select Sales Person"
                        v-model="formData.sales_person_id"
                        :api-service="fetchContactProfiles"
                        :additional-query="{ 'with_mobile': 1, 'with_donors': 1}"
                        :format-label="formatPatientLabel"
                        :additional-option="additionalOption"
                        :reduce="option => option.id"
                    />
                </div>
                <div class="col-md-3">
                    <label class="form-label">Commission %</label>
                    <input
                        class="form-control invoice-edit-input date-picker flatpickr-input flatpickr-mobile"
                        tabindex="1"
                        type="number"
                        placeholder="Please add commission %"
                        v-model="formData.commission_percent"
                    >
                </div>
                <div class="col-md-3">
                    <label class="form-label">LC No</label>
                    <input
                        class="form-control invoice-edit-input date-picker flatpickr-input flatpickr-mobile"
                        tabindex="1"
                        type="text"
                        placeholder="Please add LC number"
                        v-model="formData.lc_number"
                    >
                </div>
                <div class="col-md-3" v-if="route.query.type === 'approval'">
                    <label class="form-label">Order Type</label>
                    <v-select
                        placeholder="Select Order Type"
                        v-model="formData.sale_type"
                        :options="orderTypes"
                        label="name"
                        :reduce="name => name.value"
                    />
                </div>
                <div class="col-md-3">
                    <label class="form-label">Ship from (Warehouse)</label>
                    <v-select
                        placeholder="Select Warehouse Location"
                        v-model="formData.location_id"
                        :options="locations"
                        label="text"
                        :reduce="text => text.id"
                    />
                </div>
            </div>
        </div>

        <div class="px-2">
            <ul class="nav nav-tabs" role="tablist">
                <li class="nav-item">
                    <a @click="formData.has_item_detail = true" :class="{'active' : formData.has_item_detail}" class="nav-link" data-bs-toggle="tab"  aria-controls="home" role="tab" aria-selected="true">Item Details</a>
                </li>
                <li class="nav-item">
                    <a @click="formData.has_item_detail = false" :class="{'active' : !formData.has_item_detail}" class="nav-link" data-bs-toggle="tab" aria-controls="profile" role="tab" aria-selected="false">Accounts Details</a>
                </li>
            </ul>
        </div>

        <div class="px-3">
            <Invoice
                class="mt-2"
                v-for="(data, index) in accountsDetails"
                :key="index"
                :index="index"
                :data="data"
                :accountHeads="accountHeads"
                :vatRate="vatRate"
                :isItem="isItem"
                :locationId="formData.location_id"
                :business="business"
                :projects="projects"
                :costCentres="costCentres"
                @onClose="removeBill"
                :visitClientId="visitClientId"
            />
            <Invoice
                class="mt-2"
                v-for="(data, index) in itemsDetails"
                :key="index"
                :index="index"
                :data="data"
                :accountHeads="accountHeads"
                :vatRate="vatRate"
                :isItem="isItem"
                :locationId="formData.location_id"
                :business="business"
                :projects="projects"
                :costCentres="costCentres"
                @onClose="removeBill"
                :visitClientId="visitClientId"
            />
        </div>

        <div class="px-2 mt-2">
            <button @click="addNewBill" class="btn btn-primary">Add line</button>
        </div>

        <div class="px-2">
            <div class="row justify-content-end">
                <div class="col-12 col-sm-3">
                    <div class="row">
                        <div class="col-12">
                            <div class="mb-1 row">
                                <div class="col-sm-3">
                                    <label class="col-form-label" for="first-name">Sub total</label>
                                </div>
                                <div class="col-sm-9">
                                    <input
                                        v-model="subTotal"
                                        readonly
                                        type="text"
                                        class="form-control text-right"
                                    >
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="mb-1 row">
                                <div class="col-sm-3">
                                    <label class="col-form-label" for="first-name">VAT</label>
                                </div>
                                <div class="col-sm-9">
                                    <input
                                        v-model="totalVAT"
                                        readonly
                                        type="text"
                                        class="form-control text-right"
                                    >
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="mb-1 row">
                                <div class="col-sm-3">
                                    <label class="col-form-label" for="first-name">Total</label>
                                </div>
                                <div class="col-sm-9">
                                    <input
                                        v-model="total"
                                        readonly
                                        type="text"
                                        class="form-control text-right"
                                    >
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-12 px-2">
            <div class="mb-1">
                <label class="form-label" for="description">Memo</label>
                <vField
                    as="textarea"
                    name="description"
                    type="number"
                    class="form-control"
                    v-model="formData.description"
                />
            </div>
        </div>

        <div class="pb-5 px-2 mt-4">
            <div class="d-flex flex-wrap gap-1 gy-2">
                <button :disabled="saveButtonLoader || saveNewButtonLoader" @click="handleSubmit(true)" class="btn btn-primary">Save</button>
                <button :disabled="saveButtonLoader || saveNewButtonLoader" @click="handleSubmit(false)" class="btn btn-primary">Save & New</button>
                <button :disabled="productLoader" @click="navigateToListPage" class="btn btn-outline-secondary">Cancel</button>
            </div>
        </div>
         <Loader v-if="loader"/>
         <ContactDropdownFilter 
            v-if="store.state.isModalOpenThree"
            @onSearchContact="onSearchContact"
            :companyRoles="companyRoles"
            :isFilterable="true"
        />
    </div>
</template>

<script setup>
import { ref, inject, reactive, onMounted, computed, watch } from 'vue'
import {vatRate} from '@/data/inventory.js'
import { useRouter, useRoute } from 'vue-router'
import handlePurchase from '@/services/modules/purchase'
import handleCBusinesses from '@/services/modules/businesses'
import handleBusinessesLocations from '@/services/modules/businessesLocations'
import handleContact from '@/services/modules/contact'
import handleCostCentres from "@/services/modules/ngo/costCentre";
import handleProjects from "@/services/modules/procurement/project";
import Loader from '@/components/atom/LoaderComponent'
import TitleButton from '@/components/atom/TitleButton'
import Invoice from '@/components/molecule/company/inventory/sales/Invoice.vue'
import {generateTxnNumber} from "@/services/utils/voucherNumberGenerator";
import AsyncSelect from "@/components/molecule/input-field/AsyncSelect.vue";
import {useAsyncDropdownHelper} from "@/services/utils/asyncDropdownHelper";
import handleCrm from '@/services/modules/crm/crm'
import handleRole from "@/services/modules/role";
import ContactDropdownFilter from '@/components/atom/Contact/ContactDropdownFilter.vue'
import { useStore } from 'vuex'

const router = useRouter()
const route = useRoute()
const store = useStore();
let prefix = ref('')
let serial = ref('')
const singleSR = ref(null);
const singleDealer = ref(null);
const dealerRenderKey = ref(0);
const contactRenderKey = ref(0);

const showError =  inject('showError');
const showSuccess =  inject('showSuccess');

const {fetchProductList, fetchAccountHead, ...rest} = handlePurchase()
const {fetchBusinessList} = handleCBusinesses()
const {fetchBusinessLocationsList} = handleBusinessesLocations()
const {fetchContactProfiles, fetchSingleContactProfile } = handleContact()
const { fetchProjects } = handleProjects()
const { fetchCostCentreList } = handleCostCentres()
const { formatPatientLabel } = useAsyncDropdownHelper();
const { fetchAllDealers, fetchAllSR } = handleCrm();
const { fetchCompanyDefaultRoles } = handleRole();
const additionalOption = ref([]);
const additionalDealerQuery = ref({});
const additionalContactOption = ref({
    with_mobile: 1,
    with_donors: 1,
    dealer_id: '',
    roles: 'customer,lead'
});
const additionalDealerOption = ref([]);
const additionalSROption = ref([]);
const companyRoles = ref([]);

const companyId = computed(() => {
    return route.params.companyId
})

const orderTypes = ref([])

const visitClientId = computed(() => route.query.visit_client_id);

let loader =ref(false)
let productLoader =ref(false)
let saveButtonLoader=ref(false)
let saveNewButtonLoader =ref(false)
let accountHeads = ref([])
let business = ref([])
let locations = ref([])
let accountPayable = ref([])
let vatPayable = ref([])
let projects = ref([])
let costCentres = ref([])
let singleContact = ref({})
const contactProfile = ref(null);

onMounted(async () => {
    loader.value=true
    // const productQuery = `?company_id=${companyId.value}&product_type=finished_goods`
    let voucher = await generateTxnNumber(`?company_id=${route.params.companyId}&voucher_type=journal_voucher&txn_type=invoice_order`);
    prefix.value = voucher.prefix;
    serial.value = voucher.serial;
    formData.value.date =  new Date().toISOString().split('T')[0]

    const companyQuery = `?company_id=${companyId.value}`
    let projectQuery = companyQuery + '&with_donors=1';
    const accountHeadRes = fetchAccountHead(companyQuery)
    const businessRes = fetchBusinessList(companyQuery)
    const businessLocationRes = fetchBusinessLocationsList(companyQuery)
    const payableRes = rest.fetchAccountReceivable(companyQuery)
    const vatPayableRes = rest.fetchVatPayable(companyQuery)
    const projectRes = fetchProjects(projectQuery)
    const costCenterRes = fetchCostCentreList(companyQuery)

    Promise.all([
        rest.fetchSalesOrderTypes(companyQuery).then(res => {
            if(res.data) orderTypes.value = res.data
        }),
        accountHeadRes.then(res=> {
            if(res.data) accountHeads.value = res.data
        }),
        businessRes.then(res=> {
            if(res.data) business.value = res.data
        }),
        businessLocationRes.then(res=> {
          if(res.data) locations.value = res.data
        }),
        projectRes.then(res=> {
            if(res.data) projects.value = res.data
        }),
        costCenterRes.then(res=> {
            if(res.data) costCentres.value = res.data
        }),
        payableRes.then(res=> {
            if(res.data) accountPayable.value = res.data
            if(res.data?.length) {
                formData.value.account_head_id = res.data[0].id
            }
        }),
        vatPayableRes.then(res=> {
            if(res.data) vatPayable.value = res.data
            if(res.data?.length) {
                formData.value.vat_payable_account_head_id = res.data[0].id
            }
        }),
        getCompanyRoles()
    ])
    .then(async () => {
        loader.value=false

        if(visitClientId.value && route.name === 'sales-order-add'){
            await setVisitClientData();
        }
    })
    .catch((err)=>{loader.value=false})
//   getProduct()
})
let formData = ref({
    company_id: companyId,
    contact_profile_id: null,
    account_head_id: null,
    vat_payable_account_head_id: null,
    business_id: null,
    project_id: null,
    cost_centre_id: null,
    location_id: null,
    status: 'pending',
    bill_number: '',
    date: '',
    has_item_detail: true,
    description: '',
    account_details: [],
    order_number: null,
    sales_person_id: null,
    commission_percent: null,
    lc_number: null,
    sale_type: null,
    item_details: [
        {
            product_id: null,
            alt_unit_qty: null,
            alt_unit_id: null,
            quantity: 1,
            free_quantity: 0,
            rate: '',
            vat: 15,
            vat_amount: 0,
            description: "",
            discount_amount: 0,
            discount_percent: 0,
            business_id: null,
            project_id: null,
            cost_centre_id: null
        }
    ],
})
const hasItemDetails = computed(() => {
    return formData.value.has_item_detail
})
const itemsDetails = computed(() => {
    return formData.value.account_details
})
const accountsDetails = computed(() => {
    return formData.value.item_details
})

const isItem = computed(() => {
    return formData.value.has_item_detail ? true : false
})
const selectionText = computed(() => {
    return formData.value.has_item_detail ? 'Item details' : 'Accounting Details'
})

const subTotal = computed(() => {
    let subTotal=0
    if(formData.value.has_item_detail) {
        formData.value.item_details.map(item => {
            subTotal += parseFloat((item.quantity * item.rate) - item.discount_amount)
        })
    }
    if(!formData.value.has_item_detail) {
        formData.value.account_details.map(item => {
            if(item.amount) {subTotal += parseFloat(item.amount - item.discount_amount)}
        })
    }

    return subTotal.toFixed(2);
})

const totalVAT = computed(() => {
    let vat=0
    if(formData.value.has_item_detail) {
        formData.value.item_details.map(item => {
          if(item.vat_amount) {
            vat += parseFloat(item.vat_amount)
          }
        })
    }
    if(!formData.value.has_item_detail) {
        formData.value.account_details.map(item => {
          if(item.vat_amount) {
            vat += parseFloat(item.vat_amount)
          }
        })
    }

    return vat.toFixed(2)
})

const total = computed(() => {
    return (parseFloat(subTotal.value) + parseFloat(totalVAT.value)).toFixed(2);
})

watch(() => singleSR.value, (newValue, oldValue) => {
    additionalDealerQuery.value = {};
    additionalContactOption.value.dealer_id = '';
    singleDealer.value = null;
    contactProfile.value = null;
    formData.value.contact_profile_id = null;
    dealerRenderKey.value += 1;
    contactRenderKey.value += 1;

    if(newValue) {
        additionalDealerQuery.value = {'sale_representative_id': newValue.id}
    }
})

watch(() => singleDealer.value, (newValue, oldValue) => {
    additionalContactOption.value.dealer_id = '';
    contactProfile.value = null;
    formData.value.contact_profile_id = null;
    contactRenderKey.value += 1;

    if(newValue) {
        additionalContactOption.value.dealer_id = newValue.id
    }
})

watch(hasItemDetails, (newValue, oldValue) => {
    if(!newValue) {
        delete formData.value.item_details
        formData.value.account_details = [{
            account_head_id: null,
            vat_payable_account_head_id: null,
            amount: '',
            vat: 15,
            vat_amount: 0,
            description: '',
            taxable_amount: null,
            tax_rate: null,
            discount_amount: 0,
            discount_percent: 0,
            business_id: null,
            project_id: null,
            cost_centre_id: null
        }]
    }
    if(newValue) {
        delete formData.value.account_details
        formData.value.item_details = [{
            product_id: null,
            quantity: 1,
            rate: '',
            vat: 15,
            vat_amount: 0,
            description: "",
            discount_amount: 0,
            discount_percent: 0,
            business_id: null,
            project_id: null,
            cost_centre_id: null
        }]
    }
})

const getCompanyRoles = async () => {
    const res = await fetchCompanyDefaultRoles(`?company_id=${companyId.value}&roles=customer,lead`);
    if (res.status) {
        companyRoles.value = res.data
    }
}

const onOpenContactSearchModal = () => {
    store.state.isModalOpenThree = true;
}

const onSearchContact = (contact) => {
    contactProfile.value = contact
}

const onChangeContact = (e) => {
    //TODO: contact profile id
}

function navigateToListPage() {
    const query = Object.assign({}, route.query)
    delete query.visit_client_id;
    delete query.type;

    router.push({name: route.query.type === 'approval' ? 'sales-order-approval' : 'sales-order-list', params: route.params, query: query })
}

const setVisitClientData = async () => {
    const companyQuery = `?company_id=${companyId.value}`;
    await fetchSingleContactProfile(visitClientId.value, companyQuery).then(res => {
        if (res.data) {
           singleContact.value = {
                id: res.data.id,
                name: res.data.name
           }
        }
    })
    
    formData.value.contact_profile_id = visitClientId.value
    formData.value.from_visit_plan = 1;

    if(singleContact.value) {
        contactProfile.value = singleContact.value
    }
}

function removeBill(index) {
    if(hasItemDetails.value) {
        formData.value.item_details.splice(index, 1)
    }
    if(!hasItemDetails.value) {
        formData.value.account_details.splice(index, 1)
    }
}

function addNewBill() {
    if(hasItemDetails.value) {
        formData.value.item_details.push({
            product_id: null,
            quantity: 1,
            rate: '',
            vat: 15,
            vat_amount: 0,
            description: '',
            discount_amount: 0,
            discount_percent: 0,
            business_id: null,
            project_id: null,
            cost_centre_id: null
        })
    }
    if(!hasItemDetails.value) {
        formData.value.account_details.push({
            account_head_id: null,
            vat_payable_account_head_id: null,
            amount: '',
            vat: 15,
            vat_amount: 0,
            description: '',
            taxable_amount: null,
            tax_rate: null,
            discount_amount: 0,
            discount_percent: 0,
            business_id: null,
            project_id: null,
            cost_centre_id: null
        })
    }
}
function handleSubmit(redirect=false) {
    if (! formData.value.vat_payable_account_head_id){
      showError('Please select a vat payable account head');
      return;
    }
    if(redirect) {
        saveButtonLoader.value = true
    } else {
        saveNewButtonLoader.value = true
    }
    if(contactProfile.value) formData.value.contact_profile_id = contactProfile.value.id;
    formData.value.bill_number = prefix.value+'-'+serial.value
    if(!formData.value.date) formData.value.date = new Date().toISOString().split('T')[0]
    let copyFormData = JSON.parse(JSON.stringify(formData.value))
    copyFormData.has_item_detail = formData.value.has_item_detail ? 1 : 0
    if(isItem.value) {
        delete copyFormData.account_details
        copyFormData.item_details = formData.value.item_details
    }else {
         delete copyFormData.item_details
        copyFormData.account_details = formData.value.account_details
    }
    rest.createSalesOrder(copyFormData)
    .then(res => {
        saveButtonLoader.value = false
        saveNewButtonLoader.value = false
        productLoader.value = false
        if(res.status) {
            showSuccess(res.message)
            if(redirect) navigateToListPage()
            resetForm()
        }
        if(!res.status) {
            showError(res.message)
        }
    })
    .catch(err=> {
        saveButtonLoader.value = false
        saveNewButtonLoader.value = false
    })
}

// have to refactor
function resetForm() {
    if(!isItem.value) {
        formData.value.account_details = [{
            account_head_id: null,
            vat_payable_account_head_id: null,
            amount: '',
            vat: 15,
            vat_amount: 0,
            description: '',
            taxable_amount: null,
            tax_rate: null,
            discount_amount: 0,
            discount_percent: 0,
            business_id: null,
            project_id: null,
            cost_centre_id: null
        }]
    }
    if(isItem.value) {
        formData.value.item_details = [{
            product_id: null,
            quantity: 1,
            rate: '',
            vat: 15,
            vat_amount: 0,
            description: "",
            discount_amount: 0,
            discount_percent: 0,
            business_id: null,
            project_id: null,
            cost_centre_id: null
        }]
    }
}

</script>

<style scoped>
.contact-detail {
    font-weight: 700;
    top: 12%;
    position: absolute;
}
.filter-label {
    padding-right: 0.5rem !important;
}
</style>